import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import { render } from 'react-dom';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const Numbers = Loadable(lazy(() => import('pages/numbers')));
const UpdateLink = Loadable(lazy(() => import('pages/update-link')));
const NotFoundPage = Loadable(lazy(() => import('pages/not-found-page')));

const Profile = Loadable(lazy(() => import('pages/profile')));

// pages routing
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const Forgot = Loadable(lazy(() => import('pages/auth/forgot')));
const CheckEmail = Loadable(lazy(() => import('pages/auth/check-email')));
const ResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));

const SamplePage = Loadable(lazy(() => import('pages/sample-page')));

const Checkout = Loadable(lazy(() => import('pages/checkout')));

const LandingPage = Loadable(lazy(() => import('pages/landing-page')));
const Finance = Loadable(lazy(() => import('pages/finance')));

const MainRoutes = {

  path: '/',
  children: [
    {
      path: '/',
      element: <CommonLayout />,
      children: [
        {
          path: '/',
          element: <LandingPage />
        },
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'register',
          element: <AuthRegister />
        },
        {
          path: 'reset-password/:token',
          element: <ResetPassword />
        },
        {
          path: 'forgot',
          element: <Forgot />
        },
        {
          path: 'checkout',
          element: <Checkout />
        },
        {
          path: 'check-email',
          element: <CheckEmail />
        },
        {
          path: '*',
          element: <NotFoundPage />
        },
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: 'dashboard',
          element: <DashboardDefault />
        },
        {
          path: 'numbers',
          element: <Numbers />
        },
        {
          path: 'finance',
          element: <Finance />
        },
        {
          path: 'update-link',
          element: <UpdateLink />
        },
        ,
        {
          path: 'profile',
          element: <Profile />
        },
      ]
    },
  ]
};

export default MainRoutes;
