import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import '../../../src/assets/css/numbers.css'
// // material-ui
// import { ButtonBase } from '@mui/material';

// project-imports
// import LogoIcon from './LogoIcon';
// import { APP_DEFAULT_PATH } from 'config';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ reverse }) => (
  // <ButtonBase disableRipple component={Link} to={!to ? APP_DEFAULT_PATH : to} sx={sx}>
    // {isIcon ? <LogoIcon /> :
  <div id="logo-main">Altern.</div>
    //  }
  // </ButtonBase>
);

LogoSection.propTypes = {
  reverse: PropTypes.bool,
  // sx: PropTypes.object,
  // isIcon: PropTypes.bool,
  // to: PropTypes.string
};

export default LogoSection;
